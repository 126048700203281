<template>
  <section
    id="skills"
    class="py-32 px-5 bg-background text-secondary-color min-h-screen flex flex-col relative"
  >
    <h2 class="text-4xl font-bold text-center text-primary-color mb-10">
      Compétences
      <span class="block w-16 h-1 bg-primary-color mx-auto mt-2 rounded"></span>
    </h2>

    <div
      v-for="category in skillCategories"
      :key="category.id"
      class="category mb-16"
    >
      <h3 class="text-2xl font-semibold text-primary-color mb-6 flex justify-center md:justify-start">
        {{ category.title }}
      </h3>

      <div
        v-for="subCategory in category.subCategories"
        :key="subCategory.id"
        class="sub-category mb-8 relative border border-gray-300 rounded-lg p-6 shadow-md"
      >
        <!-- Titre de la sous-catégorie positionné sur le bord supérieur -->
        <h4
          v-if="subCategory.title !== 'Autres'"
          class="absolute -top-3 bg-background px-2 text-base md:text-lg font-medium text-primary-color/70 text-center md:text-left left-1/2 md:left-6 transform -translate-x-1/2 md:translate-x-0 z-10"
        >
          {{ subCategory.title }}
        </h4>

        <!-- Retour à la ligne conditionnel pour les petits écrans -->
        <div class="block md:hidden">
          <br>
        </div>

        <!-- Alignement responsif des compétences -->
        <div class="flex flex-wrap justify-center md:justify-start gap-6">
          <SkillIcon
            v-for="skill in subCategory.skills"
            :key="skill.id"
            :skill="skill"
            class="skill-icon"
            :size="{ width: 'w-32', height: 'h-36', iconSize: 'text-5xl', textSize: 'text-base' }"
            @open-details="openSkillDetails"
          />
        </div>
        <br>
      </div>
    </div>

    <!-- Modale pour les détails de la compétence -->
    <SkillDetailModal
      v-if="selectedSkill"
      :skill="selectedSkill"
      @close-modal="closeSkillDetails"
    />
  </section>
</template>

<script src="./SkillsOverview.js"></script>

<style scoped>
.sub-category {
  /* Ombre portée pour ajouter de la profondeur */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; /* Assurez-vous que la carte est positionnée relativement */
  z-index: 0; /* Assurez-vous que la carte a un z-index inférieur */
}

.skill-icon {
  /* Assurez-vous que les icônes de compétences ont une largeur et une hauteur cohérentes */
  width: 8rem; /* w-32 */
  height: 9rem; /* h-36 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Ajouter des styles responsifs pour les cartes de compétences */
@media (max-width: 640px) {
  .sub-category {
    padding: 1rem; /* p-4 */
  }
  .skill-icon {
    width: 6rem; /* w-24 */
    height: 7.5rem; /* h-30 */
  }
  .skill-icon img,
  .skill-icon i,
  .skill-icon span {
    width: 40px;
    height: 40px;
    font-size: 1.5rem; /* text-xl */
  }
  .skill-icon h4 {
    font-size: 1rem; /* text-base */
  }
}

@media (min-width: 641px) and (max-width: 768px) {
  .sub-category {
    padding: 1.5rem; /* p-6 */
  }
  .skill-icon {
    width: 7rem; /* w-28 */
    height: 8.75rem; /* h-35 */
  }
  .skill-icon img,
  .skill-icon i,
  .skill-icon span {
    width: 45px;
    height: 45px;
    font-size: 1.75rem; /* text-2xl */
  }
  .skill-icon h4 {
    font-size: 1.125rem; /* text-lg */
  }
}

@media (min-width: 769px) {
  .sub-category {
    padding: 2rem; /* p-8 */
  }
  .skill-icon {
    width: 8rem; /* w-32 */
    height: 9rem; /* h-36 */
  }
  .skill-icon img,
  .skill-icon i,
  .skill-icon span {
    width: 50px;
    height: 50px;
    font-size: 2rem; /* text-3xl */
  }
  .skill-icon h4 {
    font-size: 1.25rem; /* text-xl */
  }
}
</style>

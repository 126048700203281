// src/components/SkillsOverview/SkillsOverview.js
import SkillIcon from '@/components/SkillIcon/SkillIcon.vue';
import SkillDetailModal from '@/components/SkillDetailModal/SkillDetailModal.vue';
import skillsData from '@/data/skillsData.json';
import { gsap } from 'gsap';

export default {
  name: 'SkillsOverview',
  components: {
    SkillIcon,
    SkillDetailModal,
  },
  data() {
    return {
      skillsData: skillsData,
      selectedSkill: null,
    };
  },
  computed: {
    skillCategories() {
      // Créer un mapping des catégories pour un accès facile au nom en français
      const categoryMap = {};
      this.skillsData.categories.forEach(category => {
        categoryMap[category.id_categorie] = category.namecategorie_fr;
      });

      // Regrouper les compétences par catégorie
      const categoriesWithSkills = this.skillsData.categories.map(category => {
        // Trouver les compétences appartenant à cette catégorie
        const skills = this.skillsData.technologies.filter(skill => skill.category === category.id_categorie && skill.isDisplayed);

        if (skills.length === 0) {
          return null; // Ignorer les catégories sans compétences affichées
        }

        // Regrouper les compétences par sous-catégorie
        const subCategoriesMap = {};
        skills.forEach(skill => {
          const subCategoryId = skill.sub_category || 'other';
          if (!subCategoriesMap[subCategoryId]) {
            subCategoriesMap[subCategoryId] = {
              id: subCategoryId,
              title: categoryMap[subCategoryId] || 'Autres',
              skills: []
            };
          }
          subCategoriesMap[subCategoryId].skills.push(skill);
        });

        return {
          id: category.id_categorie,
          title: category.namecategorie_fr,
          subCategories: Object.values(subCategoriesMap)
        };
      });

      // Filtrer les catégories nulles (celles sans compétences)
      return categoriesWithSkills.filter(category => category !== null);
    }
  },
  methods: {
    openSkillDetails(skill) {
      this.selectedSkill = skill;
    },
    closeSkillDetails() {
      this.selectedSkill = null;
    },
  },
  mounted() {
    // Animer les icônes de compétences lors du montage du composant
    this.$nextTick(() => {
      gsap.from('.skill-icon', {
        duration: 0.5,
        opacity: 0,
        y: 30,
        //stagger: 0.02,
        ease: 'power2.out',
        onComplete: () => {
          // Réinitialiser le stagger à 0 une fois l'animation terminée
          gsap.set('.skill-icon', { stagger: 0 });
        }
      });
    });
  },
};

// src/components/ProjectModal/ProjectModal.js
import SkillIcon from '@/components/SkillIcon/SkillIcon.vue';
import SkillDetailModal from '@/components/SkillDetailModal/SkillDetailModal.vue';
import skillsData from '@/data/skillsData.json';
import { gsap } from 'gsap';

export default {
  name: 'ProjectModal',
  components: {
    SkillIcon,
    SkillDetailModal,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      skillsData,
      selectedSkill: null,
    };
  },
  mounted() {
    gsap.from(this.$refs.modalContainer, {
      duration: 0.5,
      opacity: 0,
      y: -50,
      ease: 'power2.out',
    });
  },
  methods: {
    closeModal() {
      gsap.to(this.$refs.modalContainer, {
        duration: 0.5,
        opacity: 0,
        y: -50,
        ease: 'power2.in',
        onComplete: () => {
          this.$emit('close-modal');
        },
      });
    },
    getSkill(competence) {
      const { category, sub_category, id } = competence;
      
      // Vérifier si la catégorie existe
      const categoryExists = this.skillsData.categories.find(cat => cat.id_categorie === category);
      if (!categoryExists) {
        console.warn(`Catégorie "${category}" non trouvée.`);
        return null;
      }

      // Vérifier si la sous-catégorie existe
      if (sub_category) {
        const subCategoryExists = this.skillsData.categories.find(cat => cat.id_categorie === sub_category);
        if (!subCategoryExists) {
          console.warn(`Sous-catégorie "${sub_category}" non trouvée.`);
          return null;
        }
      }

      // Trouver la compétence
      const skill = this.skillsData.technologies.find(tech => {
        const matchId = tech.id === id;
        const matchCategory = tech.category === category;
        const matchSubCategory = !sub_category || tech.sub_category === sub_category;
        
        if (matchId && (!matchCategory || !matchSubCategory)) {
          console.log('Skill trouvé mais mauvaise catégorie:', {
            skill: tech,
            expectedCategory: category,
            expectedSubCategory: sub_category,
          });
        }
        
        return matchId && matchCategory && matchSubCategory;
      });

      if (!skill) {
        console.warn(
          `Compétence avec l'identifiant "${id}" dans la catégorie "${category}"${
            sub_category ? ` et sous-catégorie "${sub_category}"` : ''
          } non trouvée.`
        );
        return null;
      }

      return skill;
    },
    openSkillDetails(skill) {
      this.selectedSkill = skill;
    },
    closeSkillDetails() {
      this.selectedSkill = null;
    },
    formatDuration(startDate, endDate) {
      if (endDate.toLowerCase() === 'present') {
        return `${startDate} - Présent`;
      }
      return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
    },
  },
};
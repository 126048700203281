// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import Accueil from '@/components/AccueilSection/AccueilSection.vue';
import AboutSection from '@/components/AboutSection/AboutSection.vue';
import EducationHistory from '@/components/EducationHistory/EducationHistory.vue';
import WorkExperience from '@/components/WorkExperience/WorkExperience.vue';
import SkillsOverview from '@/components/SkillsOverview/SkillsOverview.vue';
import ProjectsOverview from '@/components/ProjectsOverview/ProjectsOverview.vue';
import BlogList from '@/components/BlogList/BlogList.vue';
import BlogDetails from '@/components/BlogDetails/BlogDetails.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Accueil,
  },
  {
    path: '/about',
    name: 'About',
    component: AboutSection,
  },
  {
    path: '/education',
    name: 'Education',
    component: EducationHistory,
  },
  {
    path: '/experience',
    name: 'Experience',
    component: WorkExperience,
  },
  {
    path: '/skills',
    name: 'Skills',
    component: SkillsOverview,
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectsOverview,
  },
  {
    path: '/blogs',
    name: 'BlogList',
    component: BlogList,
  },
  {
    path: '/blogs/:id',
    name: 'BlogDetails',
    component: BlogDetails,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;

<template>
  <section id="projects-overview" class="relative min-h-screen pt-32 pb-10 px-5 bg-background text-secondary-color ">
    <h2 class="text-4xl mb-10 text-center text-primary-color font-bold relative">
      Projets
      <span class="block w-16 h-1 bg-primary-color mx-auto mt-2 rounded"></span>
    </h2>
    <div v-for="category in projectsByCategory" :key="category.id" class="category mb-16">
      <div class="sub-category mb-8 relative border border-gray-300 rounded-lg p-6 shadow-md">
        <!-- Titre de la catégorie positionné sur le bord supérieur -->
        <h4
          class="absolute -top-3 bg-background px-2 text-base md:text-lg font-medium text-primary-color/70 text-center md:text-left left-1/2 md:left-6 transform -translate-x-1/2 md:translate-x-0">
          {{ category.name }}
        </h4>
        <div class="grid gap-8 sm:gap-6 md:gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-6">
          <ProjectCard v-for="project in category.projects" :key="project.id" :project="project" :showDetail="false"
            class="project-card" @open-modal="openModal" />
        </div>
      </div>
    </div>
    <!-- Fenêtre modale pour afficher les détails du projet -->
    <ProjectModal v-if="selectedProject" :project="selectedProject" @close-modal="closeModal" />
  </section>
</template>
<script src="./ProjectsOverview.js"></script>
<style scoped>
.sub-category {
  /* Ombre portée pour ajouter de la profondeur */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>